// respond is the name of your mixin

@mixin respond ($breakpoint) {

  // $breakpoint is simply a variable that can have several values

  @if $breakpoint==tablet {

      // here `laptop` is the value of $breakpoint
      // when call laptop, we mean the following piece of code        

  @media only screen and (min-width: 480px) {
    @content;
  }
}

  @if $breakpoint==desktop {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 479px) {
      @content;
    }
  }


}