@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.home {
  &.main-content {
    @include respond(tablet) {
      border-radius: 0 0 0 0;
      overflow: hidden;
    }
  }
  .page {
    @include respond(tablet) {
      padding: 0px 6vw;
    }
  }
  .landing-page.page {
    // background: $primary-color;
    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    // min-height: 100vh;
    // background-color: #FFFFFF;
    // color: $text-color;
    @include respond(tablet) {
      // padding: 0;
      // text-align: left;
      // border-bottom: 1px solid black;
    }
    .primary-content {
      // position: relative;
      // padding-top: 6vh;   
      // // padding: 2rem;
      // font-style: normal;
      // font-weight: 700;
      // text-align: center;
      // .landing-img {
      //   max-width: 60%;
      // }
      @include respond(tablet) {
        // width: 70%;
        // text-align: left;
        .landing-img {
          position: absolute;
          top:6vh;
          right: 0;
          transform: translateX(85%);
          max-width: 60%;
        }
      }
      // h1 {
      //   padding-bottom: 3vh;
      //   text-align: left;
      //   font-weight: 1200;
      // }
      // div {
      //   padding-bottom: 4vh;
      //   text-align: left;
      // }
    }
    
    .secondary-content {
      // text-align: center;

      // p {
      //   line-height: 1.4rem;
      //   margin-top: 4vh; 
      // }
      @include respond(tablet) {
        // text-align: left;
        // width: 55%;
        .action-btn {
          width: 15vw;
          margin-right: 2vw;
          background-color: $primary-color;
          color: #FFFFFF;
          &.outline {
            color: $outline-color;
            border-color: $outline-color;
          }
        }
        
      }
    }
    // .secondary-text {
    //   position: relative;
    //   font-style: normal;
    //   font-weight: normal;
    //   /* or 150% */
    //   text-align: center;
  
    // }
  }
  .share-page {
    // color: $text-color;
    // background-color: #FFFFFF;
    padding-top: 8vh;
    padding-bottom: 10vh;
    @media only screen and (min-width: 600px) { 
      color: $text-color;
      padding-bottom: 20vh;
    }
    .primary-content {      
      text-align: left;
      @include respond(tablet) {
        // width: 50%;
        // display: inline-block;
        // padding-right: 1vw;
        // padding-left: 5.5vw;
        // vertical-align: top;
        .service-img-container {
          // flex-grow: 1;

          .service-main-img {
            float:right;
            max-width: 95%;
            margin-right: 0vw;
            transform: translateY(35%);
          }
        }
      }

      .share-header {
        // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        // text-align: left;
        // padding-top: 4vh;
        // padding: 0 3vw 4vh 3vw;
        // text-align: center;
        @include respond(tablet) {
          // text-align: left;
          // padding: 0 0 4vh 0;
          // font-size: 3.2rem;
        }
      }
    }
    .secondary-content {
      @include respond(tablet) {
        // display: inline-block;
        // width: 50%;
        // padding-left: 1vw;
        // p {
        //   line-height: 2.5rem;
        // }
        // .action-color {
        //   color: $text-color;
        // }
      }
      // text-align: left;
      p {
        text-align: left;
      }
    }

  }
  .services-page.page.third-page {
    // background-color: #FFFFFF;
    text-align: left;
    // padding-top: 12vh;
    // padding-bottom: 3.5vh;
    padding: 10vh 0 11vh;
    @include respond(tablet) {
      padding-bottom: 15vh;
    }
    .primary-content {  
      // text-align: center;
      padding: 0 11vw 5vh;
      text-align: left;
      .services-header {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        margin-bottom: 4vh
      }
      @include respond(tablet) {
        display: flex;
        text-align: left;
        padding-left: 5.5vw;
        padding-bottom: 0;
        .services-primary-container {
          width: 70%;
          text-align: left;
          h4 {
            text-align: left;
            color: $title-primary-blue;
            margin-bottom: 0;
          }
          p {
            // font-size: 1.15rem;
            text-align: left;
          }
        }


      }
    }
    .secondary-content {
      .services-container {
        background: $title-primary-blue;
        color: #FFFFFF;
        // border-radius: 40px;
        border-radius: 0 60px 0px 0px;
        margin: 5vh 0px;
        padding: 2.5vh 12vw;
        text-align: center;
        width: 100vw;
        // transform: translateX(-6vw);
      }
      .single-service {
        margin-bottom: 8vh;
        h2 {
          text-align: center;
        }
        p {
          text-align: center;
        }
        img {
          width: 20%;
          margin-bottom: 2vh;
          margin-top: 2vh;
          // filter: invert(60%) sepia(42%) saturate(2208%) hue-rotate(330deg) brightness(101%) contrast(89%);
          &.python-developers-img {
            width: 17%;
          }
          &.ui-ux-img {
            width: 15%;
          }
          &.sdet-engineers-img {
            width: 15%;
          }
          @include respond(tablet) {
            width: 28%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            &.python-developers-img {
              width: 24%;
            }
            &.ui-ux-img {
              width: 23%;
            }
            &.sdet-engineers-img {
              width: 22%;
            }
          } 
        }
        .feature-service {
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          margin-bottom: 2.5vh;
        }
      }
      @include respond(tablet) {
        
        .services-container {
          display: flex;
          padding: 6vh 4vw 4vh 0vw;
          border-radius: 40px;    
          width: 92vw; 
          text-align: left;
          margin: 2.5vh 4vw;
          .single-service {
            flex-basis: 25%;
            .border-cont {
              border-right: 3px solid rgba(10, 18, 58, .20);
              padding-right: 1vw;
              padding-left: 4vw;
              p {
                text-align: left;
              }
            }
            .feature-service {
              margin-top: 0;
            }
            &:first-child {
              .border-cont {
                // padding-left: 0;
              }
            }
            
            &:last-child {
              .border-cont {
                border-right: none;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .cluster-page {
    background-color: #FFFFFF;
  }
  .graph-page {

  }
  .examples-page {
    padding: 0;
    background-color: #FFFFFF;
    .examples-container {
      background-color: $base-color;
      border-radius: 60px 60px 0 0;
      text-align: center;
      padding: 5vh 8vw 0vh 8vw;
      .examples-text {
        font-size: .9rem;
        min-height: 20vh;
      }
      .MuiMobileStepper-root {
        background-color: inherit;
      }
      .examples-tablet {
        width:100%;
        height: 59vw;
        position: absolute;
        left:0;
        top:0;
      }
      .react-swipeable-view-container {
        margin-top: 2.6vw;

      }
      .tablet-cont {
        margin: -3vw;
        position: relative;
      }
      .carousel-title.mobile {
        font-size: 2.1rem;
        padding: 4vh 0;
      }


    }
    @include respond(tablet) {
      padding: 0;
      .examples-container {
        background-color: $secondary-orange;
        border-radius: 0px;
        // margin-left: 18vw;
        display: flex;
        flex-wrap: nowrap;
        gap: 10vw;

        padding: 0;
        .examples-img-cont {
          flex-basis: 60vw;
          .slanted-imager-cont {
            position: relative;
            // display: inline-block;
            // width: 40%;
          }
          .examples-img {
            width: 140%;
            position: absolute;
            transition: .3s;
            opacity: 1;
            
            &.index-0 {
              position: absolute;
              opacity: 1;
              left: -7vw;
              top: -15vh;
              z-index: 3;

              // top: 10vh;
              // left: 10vw;
            }
            &.index-1 {
              left: -23vw;
              top: -32vh;
              width: 137%;
              opacity: .7;
              transform: perspective(200vw) rotateY(0deg) rotate(1.5deg) skew(0deg, 0deg);
              z-index:2
            }
            &.index-2 {
              width: 146%;
              transform: perspective(200vw) rotateY(13deg) skew(0deg, -3deg);
              left: -33vw;
              top: -15vh;
              opacity: .7;
              z-index: 1;
            }
            &.index-3 {
              width: 158%;
              transform: perspective(20vw) rotateY(1deg) skew(0deg, -6deg);
              left: -30vw;
              top: 6vh;
              opacity: .7;
              z-index: 4;
            }
            &.index-4 {
              left: 15vw;
              top: -23vh;
              width: 116%;
              transform: perspective(70vw) rotateY(1deg) skew(0deg, 3deg);
              opacity: .7;
              z-index: 5;
            }
          }
        }
        .examples-text-cont {
          flex-basis: 30vw;
          padding: 10vh 8vw 3vh 0vw;
          z-index: 7;
          .examples-main-text {
            min-height: 30vh;
            
          }
          .carousel-title {
            font-size: 2.0rem;
            text-align: center;
            flex-grow: 1;
            color: $title-primary-blue;
          }
        }
      }
    }
  }
  .industries-page {
    background-color: #FFFFFF;
    // padding: 5vh 7vw;
    text-align: center;
    .industries-header {
      margin-bottom: 3vh;
      text-align: left
    }
    .primary-content {
      text-align: left;
      margin-bottom: 4vh;
      .action-btn {
        width: 50%;
        height: auto;
        padding: 12px;
        border-color: $action-color;
        color: $action-color;
      }

      p {
        text-align: left;
      }
    }
    .industries-chart {
      max-width: 80%;
    }
    .secondary-content {
      position: relative;
      .industry-card {
        color: $primary-color;
        padding: 0 6vw 5vh;
      }
      .industry-img {
        max-width: 43%;
        &.EdTech {
          margin-bottom: 3vh;
          max-width: 38%;
        }
        &.FinTech {
          transform: translateX(-14%);
        }
        &.Hospitality {
          max-width: 50%;
          transform: translateX(-13%);
        }
        &.Medical {
          transform: translateX(-13%);
        }
      }
      .industry-text {
        color: $primary-color;
        max-width: 25%;
        position: absolute;
        text-align: left;

        h3 {
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
        &.EdTech {
          right: 4vw;
          bottom: 8vh;
        }
        &.FinTech {
          bottom: 17vh;
          left: 3vw;
        }
        &.Hospitality {
          right: 4vw;
          top: 0;
        }
        &.Medical {
          top: 2vh;
          left: 3vw;
        }
      }
      .industries-div {
        width: 100%;
        margin-bottom: 2.5vh;
        color: $primary-color;
        h2 {
          margin-top: .8vh;
          font-weight: 700;
        }
        p {
          margin-bottom: 9vh;
        }
        .industry-img {
          max-width: 43%;
          &.EdTech {
            margin-bottom: 3vh;
            max-width: 38%;
          }
          &.FinTech {
            transform: translateX(-14%);
          }
          &.Hospitality {
            max-width: 50%;
            transform: translateX(-13%);
          }
          &.Medical {
            transform: translateX(-13%);
          }
        }
      }
    }
    @include respond(tablet) {
      padding: 20vh 6vw 15vh;
      background-color: inherit;
      .primary-content {
        margin-left: 2vw;
        text-align: left;
        width: 70%;
        .industries-header {
          color: $title-primary-blue;
          text-align: left;
          font-size: 5rem;
            line-height: 6rem;
        }
        p {
          text-align: left;
        }
        .action-btn {
          width: auto;
          padding: 1.5vh 3vw;
          color: $off-base-color;
          border-color: $off-base-color;
        }
        p {
          color: $outline-color;
        }
      }
      .secondary-content {
        p {
          text-align: left;
        }
        .industry-text {
          h3 {
            font-size: 1.8rem;
            margin-bottom: 2vh;
          }
          &.Medical {
            margin-left: 5vw;
            width: 20vw;
          }
          // &.EdTech {
          //   margin-right: 5vw;
          //   width: 18vw;
          // }
          &.Hospitality {
            margin-left: 10vw;
            width: 20vw;
          }
        }
        // .industries-div {

        //   display: flex;
        //   flex-wrap: wrap;
        //   justify-content: space-between;
        //   row-gap: 60vh;
        //   .industry-text {
        //     text-align: left;
        //     width: 27vw;
        //     padding: 3.5vh 2vw;
        //     position: relative;
        //     h3 {
        //       font-size: 1.8rem;
        //       margin-bottom: 2vh;
        //       margin-top: 0;
        //     }
        //     &.FinTech {
        //       margin-top: 4vh;
        //       .industry-img {
        //         bottom: -10vh;
        //         transform: translate(50%, 100%);
        //       }
        //     }
        //     &.Medical {
        //       order: 2;
        //       margin-right: 5vw;
        //       .industry-img {
        //         bottom: -2vh;
        //         left: -4vw;
        //         transform: translate(-100%, 100%);

        //       }
        //     }
        //     &.Hospitality {
        //       margin-top: 30vh;
        //       order: 3;
        //       .industry-img {
        //         top: -2vh;
        //         right: -4vw;
        //         transform: translate(100%, -100%);

        //       }
        //     }
        //     &.EdTech {
        //       order: 4;
        //       margin-bottom: 20vh;
        //       .industry-img {
        //         top: -20vh;
        //         // left: -4vw;
        //         transform: translate(0%, -100%);
  
        //       }
        //     }
        //     .industry-img {
        //       position: absolute;
        //     }

        //   }
        // }
      }

    }
  }
  .products-page {
    // padding: 10vh 10vw 2vh 10vw;
    background-color: $title-primary-blue;
    color: #FFFFFF;
    border-radius: 0 60px 0 0;
    display: flex;
    flex-wrap: wrap;
    .primary-content {
      margin-bottom: 5vh;
      h2 {
        text-align: left;
        margin-bottom: 5.5vh;
      }
      h5 {
        color: $action-color;
      }
    }
    .secondary-content {
      .learn-more {
        margin-top: 3vh;
      }
      .sitting-dude {
        max-width: 45%;
        transform: translateX(110%);
      }
    }
    p {
      // font-size:1rem;
      text-align: left;
    }
    @include respond(tablet) {
      background-color: #FFFFFF;
      padding: 0;
      border-radius:0;
      .product-insert {
        padding: 0vh 10vw 7vh 12vw;
        display: flex;
        flex-wrap: wrap;
        background: $title-primary-blue;
        border-radius: 0 114px 0 0;
        width: 88%;
      }
      .product-container {
        width: 80%;
      }
      .primary-content {
        // width: 50%;
        margin-bottom: 5vh;
        margin-top: 6vh;
        text-align: left;
        h2 {
          text-align: left;
          font-size: 5rem;
          line-height: 6rem;
        }
        h5 {
          font-size: 1.8rem;
          color: #FFFFFF;
        }
        // p {
        //   font-size: 1.3rem;
        // }
      }
      .secondary-content {
        // width: 50%;
      }
      h5 {
        font-size: 1.8rem;
      }
      // p {
      //   font-size: 1.3rem;
      // }
      .sitting-dude-container {
        max-width: 20%;
        .sitting-dude {
          position: absolute;
          max-width: 25%;
          transform: translate(26%, 25%);
          // z-index: 9999;
        }

      }
    }
  }
  .background-triangle {
    &.products {
      background-color: #FFFFFF;
      border-color: $base-color transparent transparent transparent;

    }
  }

  .technologies-page {
    color: $off-base-color;
    background-color: #373939;
    text-align: center;
    padding: 11vh 6vw;
    background-color: inherit;
    position: relative;
    p {
      font-size: 1.15rem;
    }
    .primary-content{
      padding: 0 1.5vw 4vh 1.5vw;
      .technologies-header {
        // margin-bottom: 3vh;
      }
    }
    .secondary-content{
      position:relative;
      .technologies-list {
        background-color: #FFFFFF;
        border-radius: 50px;
        color: $title-primary-blue;
        padding: 5vh 6vw;
        // margin-bottom: 10vh;
        // backdrop-filter: blur(20px);
        box-shadow: 1px 0px 24px 0 rgba(0,0,0,.25);
        .single-tech {
          margin-bottom: 3vh;
          img {
            max-width: 18%;
          }
          h3 {
            margin-top: .5vh;
            color: $action-color;
          }
          h2 {
            margin-top: .5vh;
            color: $action-color;
          }
          hr {
            border: 1px solid rgba(93, 95, 239, 0.25);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            transform: rotate(0.34deg);
            margin: 4vh 15vw 0 15vw;
          }
        }
      }
    }
    .atom.mobile {
      max-width: 25%;
      position: absolute;
      top: 0;
      z-index: 99;
      right: 0;
      transform: translate(0%, -50%);
    } 
    @include respond(tablet) {
      background-color: #FFFFFF;
      color: $outline-color;
      padding: 20vh 8vw 10vh 8vw;
      .primary-content {
        display: flex;
        margin-top: 0;
        margin-bottom: 7vh;
        padding: 0;
        padding-left: 3vw;
        text-align: left;
        width: 70%;
        // line-height: 1.5rem;
        gap: 0vw;
        p {
          font-size: 1.15rem;
          padding-top: 2vh;
          text-align: left;
        }
        h2 {
          // line-height: 3.5rem;
          text-align: left;
        }
        
      }
      .secondary-content {
        position: relative;
        margin-top: 0vh;
        .tech-flex-cont {
          background: rgba(255, 255, 255, 0.6);
          display: flex;
          color: $outline-color;
          text-align: left;
          margin-bottom: 12vh;
          padding: 5vh 4.5vw;
          border-radius: 114px 114px 0 0;
          gap: 3vw;
          box-shadow: 0px 8px 25px 30px rgba(0, 0, 0, 0.04);
          backdrop-filter: blur(10px);
          .single-tech {
            border-right: 2px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 10vh;
            padding-right: 1vw;
            img {
              max-width: 22%;
              transform: translateX(-.3vw);
            }
            .single-tech-name {
              color: $action-color;
              font-size: 1.5rem;
              margin-top: 1.4vh;
            }
            p {
              font-size: 1.15rem;
              text-align: left;
            }
            &:last-child {
              border: none;
              padding-right: 0vw;
            }
          }
          &.second {
            border-radius: 0 0 114px 114px;
          }
        }
      }
      .atom {
        max-width: 15%;
        transform: translate(50%, 65%);
        bottom: 0;
        position:absolute;
        right:50%;
        z-index: 99;
      }
    }
  }



  .learn-more {
    color: #E84702;
    text-decoration-line: underline;
    svg {
      transform: translate(10px, 5px);
    }
  }
}

.landing-page {
  h3 {


    @include respond-to ('large') {
      position: absolute;
      width: 490px;
      height: 114px;
      left: 140px;
      top: 680px;
  
      font-family: Ubumedium;
      font-size: 50px;
      line-height: 57px;
      color: #424B5A;
    }
    // &.post-header {
    //   text-align: center;
    //   margin: 5rem 3rem;
    //   @include respond-to ('large') {
    //     position: relative;
    //     width: 700px;
    //     height: 114px;
    //     textAlign: center;
    //     top: 610px;

    //     font-family: Ubumedium;
    //     fontSize: 50px;
    //     lineHeight: 57px;
    //     color: #424B5A;
    //   }
    // }
  }
  .secondary-text {
    // position: relative;
    // font-family: Ubuntu;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 1rem;
    // line-height: 1.5rem;
    // margin: 2rem;
    // /* or 150% */

    // color: #A1AEB7;
    @include respond-to(large) {
      position: absolute;
      width: 590px;
      height: 144px;
      left: 820px;
      top: 680px;
      
      fontFamily: Ubu;
      fontStyle: normal;
      fontWeight: normal;
      fontSize: 18px;
      lineHeight: 24px;
      color: #A1AEB7;
    }
    .learn-more {
      
    }
  }
}

