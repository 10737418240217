@import '../../_variables.scss';
@import '../../_mixins.scss';

.navbar {
  height: 40px;
  position:fixed;
  top:0;
  background:white;
  width: 100%;
  // background: red;

}

.title-first-letter {
  font-size: 1.4em;
}

.logo {
  max-width: 50%;
  max-height: 4vh;
}

.logo-box {
  min-width: 10vw;
  transition: opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-link {
  color: $off-base-color;
  // min-width: 9vw;
  font-family: "Radio Canada";
  font-weight: 500;
  font-size: 1.4rem;
  margin: 42px 0vw 28px 3vw;
  &.active {
    color: $action-color;
  }
  svg {
    transform: translateY(25%);
    margin-left: .5vw;
    // margin-right: 2vw;
  }
  &.Services-nav-link {
    // margin-left: 0;
  }
}

.company-name {
  max-height: 5vh;
  transform: translate(6px, 2px);
}

.company-name-menu {
  max-height: 2.3rem;
  margin-bottom: 4vh;

}
.nav-menu-item-container {

  .nav-menu-item {
    font-family: "Solway";
    font-size: 1.4rem;
    line-height: 7vh;
    font-weight: 500;
    width: 100%;
    display:flex;
    color: $title-primary-blue;
    span {
      flex-grow: 1;
    }
  }
  
  .menu-arrow {
    transform: translateY(85%);
    color: $off-action-color;
    // float: right;
  }
}
.action-btn.MuiButton-textSizeMedium  {
  background-color: $action-color;
  color: #FFFFFF;
  font-size:1.2rem;
  font-weight: 900;
  height: 5.5vh;
  min-height: 50px;
  padding: 1rem 2.3rem;
  min-width: 65%;
  height: 5.7vh;
  border-radius: 10px;
  margin-top: 7.5vh;
  text-transform: none;
  // box-shadow: -.5px 3px 5px rgba(0,0,0,0.3);
  @include respond(tablet) {
    min-width: 14.5vw;
    margin-top: 1vh;
    height: 4.5vh;
    // margin-right: 1.5vw;
    padding: 0;
    font-size: 1.3rem;
  }
  &.outline {
    background-color: transparent;
    color: $navbar-button-color;
    border: 2px solid $navbar-button-color;
    font-weight: 500;
    // min-width: 20vw;
  }
  &.hire {
    width: 75%;
    font-weight: 400;
  }
}