@import './_variables.scss';
@import './_mixins.scss';

.App {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 21px;
  color: $title-primary-blue;

  // padding-top: 80px;

  @media only screen and (min-width: 600px) { 
    font-size: 1rem;
  }

  
  h1 {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 1100;
    font-size: 2.85rem;
    line-height: 3.4rem;

    @media only screen and (min-width: 600px) { 
      font-size: 6rem;
      line-height: 7.2rem;
    }
    
  }
  
  h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    font-weight: 700;
    @media only screen and (min-width: 600px) { 
      font-size: 5.3rem;
      line-height: 7rem;
    }
  }
  
  h3 {
    font-size: 1.4rem;
    line-height: 24px;  
    @include respond(tablet) {
      font-size: 5rem;
      line-height: 6rem;
    }
  }
  
  h4 {
    font-size: 1.3rem;
    line-height: 2.2rem;
    @include respond(tablet) {
      font-size: 4.3rem;
      color: $outline-color;
      line-height: 5rem;
    }
  }

  h5 {
    @include respond(tablet) {
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  h6 {
    @include respond(tablet) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }

  p {
    line-height: 1.6rem;
    font-size: 1.15rem;
    text-align: center;
    @include respond(tablet) {
      font-size: 1.15rem;
      line-height: 1.93rem;
    }
  }
  .main-div {
    background-color: #FFFFFF;
    @media only screen and (min-width: 600px) { 
      // padding: 7.5vh 0vw 0 0;
      background:  linear-gradient(#EEEFF2, #FFFFFF);
      // padding: 80px 0 0 0;
    }
  }
  .landing-page.page {
    // margin: 13vh 17vw 5vh 12vw;
    height: 100vh;
    text-align: left;
    @include respond(tablet) {
      padding: 0;
      
      color: $title-primary-blue;
      p {
        text-align: left;
        margin-bottom: 6vh;
      }
      // h1 {
      //   margin-bottom: 6.25vh;
      //   margin-left: 20vw;
      // }
      .secondary-content {
        // display: flex;
        // flex-wrap: wrap;
        // column-gap: 2vw;
        // margin-top: 0;
        p {
          flex-basis: 100%;
          margin-bottom: 0;
        }

      }
    }
  }
  .main-template.page {
    @include respond(tablet) {
      padding: 0vh 13.5vw;
      .primary-content {
        display: flex;
        // grid-gap: 3%;
        gap: 3%;
      }
      p {
        text-align: left;
        flex-basis: 50%;
      }
      h2 {
        text-align: left;
        flex-basis: 47%;
      }
      .secondary-content {
        margin-top: 15vh;
        margin-bottom: 15vh;
      }
      // text-align: left;
    }
  }

  .page {
    padding: 10vh 11vw;
    // padding-right: 6vw;
    text-align: left;
    @media only screen and (min-width: 600px) { 
      padding-left: 13.5vw;
      padding-right: 13.5vw;
      // background-color: red !important;
    }
    .action-btn {
      background-color: $action-color;
      color: #FFFFFF;
      font-size:4vw;
      font-weight: 900;
      height: 5.5vh;
      min-height: 50px;
      padding: 1rem 2.3rem;
      min-width: 65%;
      height: 5.7vh;
      border-radius: 10px;
      margin-top: 3.5vh;
      // box-shadow: -.5px 3px 5px rgba(0,0,0,0.3);
      @include respond(tablet) {
        width: 14.5vw;
        height: 5.5vh;
        // margin-right: 1.5vw;
        padding: 0;
        font-size: 1.3rem;
      }
      &.outline {
        background-color: transparent;
        color: $outline-color;
        border: 2px solid $outline-color;
        // min-width: 20vw;
      }
      &.hire {
        width: 75%;
        font-weight: 400;
      }
    }
    &.second-page {
      text-align: left;
      color: $title-primary-blue;
      padding-bottom: 4vh;
      padding-top: 0;
      span {
        color: $action-color;
      }
      .primary-content {
        margin-bottom: 5vh;
        h1 {
          font-size: 1.8rem;
          line-height: 2.4rem;
          width: 55%;
        }
        p {
          text-align: left;
        }
      }
      .secondary-content {
        // text-align:left;
        p {
          text-align: left;
        }
      }
      @include respond(tablet) {
        padding: 10vh 0 0 19vw;
        
        .primary-content {
          margin-top: 2vh;
          margin-bottom: 0;
          gap: 0;
          h2 {
            color: $title-primary-blue;
            text-align: left;
            padding: 0;
            flex-basis: 80%;
            max-width: 54vw;
            position: relative;
            span {
              color: $action-color;
            }
          }
        }
        .secondary-content {
          margin-left: 0vw;
          margin-top: 2vh;
          padding: 0;
          width: 45%;
          p {
            color: $title-primary-blue;
            position: relative;
          }
        }

      }
    }
    &.third-page {
      text-align: left;
      padding-top: 0;
      .primary-content {
        text-align: center;
        padding-bottom: 0;
      }
      h2 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
  }
  .mobile-cluster-container {
    height: 40vh;

    // margin-top: -15vh;
    .mobile-cluster {
      margin-left: -11vw;
      height: 60vh;
      // position: absolute;
      z-index: 0;
      // z-index: 9999;
      // position: relative;
      &.cover {
        height: 80vh;
        position: absolute;
        z-index: 9999;
        // background-color: black;
        width: 100%;
        // pointer-events: none;
      }
    }
  }


  .landing-page{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    min-height: 40vh;
    text-align: left;
    padding-bottom: 0;
    // padding: 0;
    .primary-content {
      padding-top: 4vh;
      // height: 50vh;
      // margin: 13vh 15vw 3vh 14vw;
      // padding: 13vh 10vw 3vh 10vw;
      margin-bottom: 4vh;
      h1 {
        text-transform: uppercase;
        // font-size: 8vw;
      }
      span {
        color: $off-action-color;
      }
    }
    .secondary-content {
      // margin-top: 50vh;
      // margin: 0 14vw;
      text-align: left;
      p {
        text-align: left;
        line-height: 1.7rem;
        // font-size: 2vh;
      }
      
    }

    @include respond(tablet) {
      min-height: calc(100vh);
      h1 {
        position: relative;
      }
      .primary-content {
        padding: 29vh 0vw 0vh 19vw;
        margin: 0;
        color: $title-primary-blue;
        width: 80%;
        
        // height: 17vh;
      }
      .secondary-content {
        padding: 5vh 0vw 0vh 19vw;
        width: 53%;
        margin: 0;
        text-align: left;
        p {
          margin-bottom: 4vh;
          position: relative;
        }
        button {
          position: relative;
        }
      }
    }
  }
}

.background-triangle {
  content: "\A";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.5vh 50vw 0 50vw;
  background-color: $base-color;
  border-color: #FFFFFF transparent transparent transparent;
  @include respond(tablet) {
    border-width: 15vh 46vw 0 46vw;
    border-color: $off-secondary-color transparent transparent transparent;
  }
}

.angled-triangle {
  content: "\A";
  width: 0;
  height: 0;
  bottom: 0;
  // margin-bottom: auto;

  position: absolute;
  border-style: solid;
  border-width: 6.5vh 10vw 0 10vw;
  background-color: #FFFFFF;
  // border-color: #blac transparent transparent transparent;
  @include respond(tablet) {
    width: 49.5vw;
    border-width: 28vh 0vw 0 49.5vw;
    border-color: $outline-color transparent transparent transparent;
  }
}


.divider {
  border-top: 3px solid rgba(10, 18, 58, 0.2);
box-shadow: -1596px 0px 4px rgba(0, 0, 0, 0.25);
  width: 30%;
  &.thick {
    border-top-width: 5px;
  }
}


.css-mgur8 {
  z-index: 9999;
}


.action-color {
  color: $action-color;
}

.action-color-tablet {
  @include respond(tablet) {
    color: $action-color;
  }
}

.action-color-mobile {
  @include respond(mobile) {
    color: $action-color;
  }
}

.title-primary-orange {
  color: $off-action-color;
}

.mobile {
  @include respond(tablet) {
    display: none !important;
  }
}

.tablet {
  @include respond(mobile) {
    display: none !important;
  }
}


