@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.technologies {
  &.main-content {
    @include respond(tablet) {
      border-radius: 30px;
      overflow: hidden;
    }
  }
  .page {
    @include respond(tablet) {
      padding: 0px 6vw;
      h3 {
        // font-size: 3rem;
        // line-height: 150%;
      }
    }
  }
  .landing-page {
    // background: #FFFFFF;
    // text-align: center;
    // height: 100vh;
    // padding: 5vh 6vw 0 6vw;
    .primary-content {
      // margin-top: 5vh;
    }
    .secondary-content {
      // margin-top: 50vh;
      .action-btn {
        // background-color: $primary-color;
      }
    }
    @include respond(tablet) {
      background-color: inherit;
      text-align: left;
      height: auto;
      .primary-content {
        // width: 50%;
        margin-top: auto;
      }
      .secondary-content {
        // margin-top: 5vh;
        // width: 50%;
        .action-btn.outline {
          margin-left: 2vw;
        }
      }
    }
  }
  .intro-page {

    p {
      text-align: left;
      span {
        color: $primary-color;
      }
    }
    @include respond(tablet) {
      background-color: inherit;
      .primary-content {
        display: flex;
        h2 {
          margin: 0;
        }
        p {
          width: 50%;
        }
      }
    }
  }
  .list-page {

    // margin-bottom: 5vh;
    .primary-content {
      // padding: 6vh 4vw;
      // margin:0 7vw;
      p {
        // width: 50%;
      }
    }
    .secondary-content {
      padding: 4vh 9vw;
      margin-top: 7vh;
      background-color: $off-secondary-color;
      box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.25);
      // backdrop-filter: blur(20px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 50px;
      .single-tech {
        margin-bottom: 4vh;
        position: relative;
        img {
          width: 30%;
          position: absolute;
          transform: translate(42vw, -25%);
        }
        h2 {
          color: $primary-color;
          margin-bottom: 3vh;
          text-align: center;
        
        }
        p {
          text-align: center;
        }
        &.alt {
          h2 {
            color: $action-color;
          }
          img {
            width: 35%;
            transform: translate(-24%, -30%);
          }
        }

      }
    }
    &.page {

      @include respond(tablet) {
        padding: 0;
        .primary-content {
          text-align: left;
          padding: 0vh 12vw;
          display: flex;
          gap: 3%;
          margin: 0;
          width: 80%;
          h2 {
            text-align: left;
            flex-basis: 47%;
          }
          p {
            text-align: left;
            flex-basis: 50%;
          }
        }
        .secondary-content {
          // border-radius: 114px 0 0 114px;
          margin-bottom: 5vh;
          margin-top: 0;
          box-shadow: none;
          padding: 0 5vw;
          background-color: inherit;
          .industries-list-container {
            display: flex;
            gap: 20%;
            padding: 0vh 7vw;
            flex-wrap: wrap;
            .single-tech {
              flex-basis: 40%;
              margin-bottom: 9vh;
              h5 {
                text-align: left;
                margin-bottom: 3vh;
                font-size: 2.2rem;
                color: $off-action-color;
              }
              p {
                text-align: left;
              }
              img {
                width: 40%;
                transform: translate(80%, -22%);
                right: 0;
              }
              &.tabAlt {
                h5 {
                  color: $primary-color;
                }
                img {
                  width: 40%;
                  bottom: 0;
                  right: 0;
                  transform: translate(20%, 110%);
                }
              }
            }
          }
        }
      }
    }
  }
  .final-page {
    padding: 0;
    .primary-content {
      text-align: center;
      background-color: $title-primary-blue;
      color: #FFFFFF;
      padding: 6vh 9vw;
      .single-IT {
        margin-bottom: 4.5vh;
        img {
          width: 16%;
        }
        h5 {
          margin-bottom: 3vh;
          // font-size: 1.3rem;
        }
        &.it-audit {
          img {
            width: 18%;
          }
        }
      }

    }
    @include respond(tablet) {
      .primary-content {
        text-align: left;
        display: flex;
        align-items: stretch;
        gap: 6%;

        .single-IT {
          flex-basis: 25%;
          // padding-right: 2.5%;
          // padding-left: 2.5%;
          span {
            border-right: 4px solid rgba(10, 18, 58, 0.2);
          }
          p {
            text-align: left;
          }
          h5 {
            text-align: left;
            // font-size: 2.2rem;
            margin-bottom: 4vh;
          }
          img {
            width: 35%;
          }
          &.it-audit {
            img {

              width: 45%;
            }
          }
          &.digital-trans {
            img {
              width: 32%;
            }
          }
        }

      }
    }
  }
}