@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.industries {
  &.main-content {
    @include respond(tablet) {
      border-radius: 30px;
      overflow: hidden;
    }
  }
  .page {
    @include respond(tablet) {
      padding: 0px 6vw;

    }
  }
  .landing-page {
    @include respond(tablet) {
      // background-color: #FFFFFF;
      text-align: left;
      height: auto;
      .primary-content {
        // width: 50%;
        // margin-top: 0vh;
      }
      .secondary-content {
        margin-top: 5vh;
        width: 50%;
        .action-btn.outline {

        }
      }
    }
  }
  .intro-page {
    background-color: #FFFFFF;
    // padding: 0 7vw 4vh 7vw;
    .primary-content {
      h2 {
        // margin: 0vh 3vw 4vh 3vw;
      }

    }
    p {
      // text-align: left;
      span {
        color: $action-color;
      }
    }
    @include respond(tablet) {
      background-color: inherit;
      .primary-content {
        display: flex;
        gap: 10vw;
        h2 {
          margin:0;
          width: 60%;
        }
        p {
          // width: 70%;
        }
      }
    }

  }
  .background-triangle {
    border-color: #FFFFFF transparent transparent transparent;

  }
  .list-page {
    text-align: center;
    background-color: #FFFFFF;
    padding-bottom: 5vh;
    .primary-content {
      h2 {
        width:80%;
        // margin-left: 5vw;
        // margin-right: 5vw;
      }
      .action-btn {
        width: auto;
        padding-left: 8vw;
        padding-right: 8vw;
      }
      p {
        span {
          color: $action-color;
        }
      }
    }
    .secondary-content {
      padding-top: 5vh;
      .industries-list-container {
        img {
          width: 40%;
        }
        margin-top: 5vh;
        text-align: center;
        .industries-card {
          background-color: $base-color;
          border-radius: 0px 60px;
          margin-bottom: 5vh;
          padding: 2vh 3vw;
          h3 {
            margin: 2vh 0 1vh 0;
            color: $primary-color;
          }
          p {
            text-align: center;
          }
          &.alt {
            // background-color: $title-primary-blue;
            color: #FFFFFF;
            h3 {
              color: $action-color;
            }
          }
        }
      }
    }
    @include respond(tablet) {
      text-align: left;
      padding: 4vh 6vw 0 6vw;
      background-color: inherit;
      .primary-content {
        // width: 65%;
        padding: 0 5.5vw 0 7.5vw;
        display: flex;
        margin-top: 10vh;
        gap: 3%;
        h4 {
          // width: 70%;
          text-align: left;
          margin-bottom: 0;
          // flex-basis: 47%;
        }
        p {
          text-align: left;
          flex-basis: 50%;
          margin-top: 2vh;
        }
      }
      margin-top: 10vh;
      .primary-content {
        h2 {
          margin: 0;
        }
      }
      .secondary-content {
        padding-top: 0;
        .industries-list-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10%;
          background: $title-primary-blue;
          border-radius: 114px 114px 0 0;
          padding: 4vh 7vw 12vh 7vw;
          
          .industries-card {
            background-color: $title-primary-blue;
            border-radius: 114px 114px 0 0;
            position: relative;
            flex-basis: 45%;
            // border-radius:inherit;
            color: #FFFFFF;
            // width: 47%;
            padding: 0;
            padding-top: 6vh;
            margin-top: 6vh;
            img {
              position: absolute;
              right: 8vw;
              top:0;
              transform: translateY(0%);
              width: 40%;
            }
            h5 {
              margin-bottom: 4vh;
              font-size: 2.15rem;
              text-align: left;
            }
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .delivery-page {
    padding: 0;
    // margin-bottom: 7vh;
    .primary-content {
      background-color: $secondary-orange;
      color: $primary-color;
      text-align: center;
      padding: 4vh 11vw;
      // margin-bottom: 12vh;
      position: relative;
      h2 {
        text-align: left;
      }
      p {
        text-align: left;
      }
      .read-more {
        color: $action-color;
        font-size: 1.3rem;
        text-decoration: underline;
        text-align: center;
        svg {
          transform: translateY(25%);
        }
      }
      img {
        width: 35%;
        position: absolute;
        transform: translateX(50%);
      }
    }
    .secondary-content {
      margin: 12vh 5vw 5vh;
      text-align: center;
      background-color: $off-secondary-color;
      border-radius: 50px 50px 50px 0px;
      padding: 5vh 10vw;
      .single-delivery {
        padding: 1vh 0vw;
        img {
          display: inline-block;
          max-width: 12%;
          transform: translate(-20%, 31%);
        }
        h2 {
          color: $action-color;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 3vh;
        }
        &.alt {
          h2 {

            color: $off-base-color;
          }
        }
      }
    }
    @include respond(tablet) {
      padding-bottom: 6vh;
      .primary-content {
        padding: 10vh 13.5vw;
        text-align: left;
        backdrop-filter: blur(20px);
        background-color: inherit;
        h3 {
          color: $primary-color;

        }
        .flex-cont {
          display: flex;
          gap: 12%;
          .flex-child {
            flex-basis: 50%;
            p {
              text-align: left;
              color: $outline-color;
              span {
                color: $primary-color;
              }
            }
            .button-cont {
              display: flex;
              gap: 2vw;
              button {
                flex-basis: 50%;
                min-width: 45%;
                height: 3.5vh;
                min-height: 4.5vh;
                &.hire {
                  background-color: $primary-color;
                  font-size: .95rem;
                }
                &.outline {
                  border-color: $primary-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
        img {
          width: 20%;
          position: absolute;
          /* transform: translateX(10%); */
          right: 23vw;
          bottom: -4vh;
        }
      }
      .secondary-content {
        background-color: inherit;
        margin: 11vh 0;
        padding: 5vh 6vw;
        h4 {
          text-align: left;
          margin-bottom: 3vh;
          margin-left: 4vw;
        }
        text-align: left;

        .flex-cont {
          display: flex;
          gap: 3%;
          background-color: $secondary-orange;
          box-shadow: -2px 6px 27px -1px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(10px);
          border-radius: 30px;
          padding: 3vh 5vw 5vh 5vw;
          .single-delivery {
            flex-basis: 25%;
            img {
              width: 2.5rem;
              max-width: 30x;
              transform: translate(58%, 10%);
            }
            p {
              text-align: left;
              line-height: 1.72rem;
            }
            h2 {
              margin: 0;
              color: $outline-color;
              font-size: 2.2rem;
            }
          }
        }
      }
    }
  }
}