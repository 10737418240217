$primary-color: #5D5FEF; //secondary-blue
$secondary-color: #FA7F4B;
$tertiary-color: #E84702;

$base-color: #EDEFFA; 
$secondary-color: #3E4567;
$off-base-color: #6567AB;
$highlight-base-color: #6B6EDB;
$third-off-color: #4B4B91;
$title-primary-blue: #4B4B91; //(75,75,145)
$light-base-color: #E6E4FF;
$off-secondary-color: #EEEFF2;
$action-color: #DE784C;
$off-action-color: #F17D4C; //Title Primary orange (241,125,76)

$text-color: #424B5A;
$secondary-orange: #FFE2D5;
$navbar-button-color: #4539CF;

$process-color: #3E4567;
$outline-color: #3D4675;
$gradient-color: linear-gradient(94.45deg, #333945 0.68%, #514E66 101.98%);

$breakpoints: (
  'small':  ( min-width:  480 ),
  'medium': ( min-width:  768 ),
  'large':  ( min-width: 1024 )
) !default;

// $base-color: #6567AB; 
// $secondary-color: #FED2B8;
// $off-base-color: #6B6EDB;
// $off-secondary-color: #FFF1E9;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
   @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}